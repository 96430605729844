html, body {
  margin:0;
  padding:0;
  background-color: rgb(199, 207, 230);
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Navigation */

.nav {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: rgb(35, 0, 131);
}

.nav .navlink {
  float: left;
  display: block;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  cursor: pointer;
  transition: 0.2s ease-out;
}

/* .nav li a{
  color: white;
  text-decoration: none;
} */

.nav .navlink:hover {
  background-color: rgb(185, 169, 224);
}

/* Draw Page */

.draw-page {
  display: flex;
  justify-content: space-between;
}

.draw-container {
  display: flex;
  /* flex-flow: column wrap; */
  align-items: center;
  flex-flow: column wrap;
  height: calc(100vh - 46px);
}

.draw-container h3 {
  margin: 4px;
}

canvas {
  border: 4px solid black;
  background-color: white;
  margin-left: 32px;
  margin-right: 32px;
  margin-top: 4px;
}

canvas:hover {
  /* cursor: url("https://i.ibb.co/8728h8K/cursor-crosshair.png") 15 15,crosshair; */
  cursor: url("https://i.imgur.com/FqRFRkv.png") 15 15,crosshair;
}

.toolbox {
  float: right;
  padding-left: 16px;
  padding-right: 16px;
  background-color: rgb(168, 168, 168);
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  border: 4px solid black;
}

/* Color Palette */

.palette {
  display: flex;
  flex-flow: row wrap;
  margin: 8px;
}
/* 
.color {
  padding: 2px;
}

.color:hover {
  background-color: #ff7675;
} */

.swatch {
  margin: 2px;
  border: 2px solid black;
  width: 32px;
  height: 32px;
}

.swatch:hover {
  border: 4px solid rgb(143, 207, 145);
  margin: 0px;
}

.add-color-button {
  position: relative;
  display: block;
  color: black;
  height: 32px;
  width: 32px;
  font-size: 24px;
  font-style: bold;
  text-decoration: none;
  border: 2px solid black;
  margin: 2px;
  border-radius: 24px;
  background-color: white;
  text-transform: uppercase;
  overflow: hidden;
}

.add-color-button:hover {
  color: #ff7675;
  margin: 0px;
  border: 4px solid #ff7675;
  cursor: default;
}

.color-picker-wrapper {
  /* margin-left: 60px; */
}

/* Pattern Thumbnails */

.pattern-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}

.pattern-card {
  margin: 24px;
  width: 284px;
  height: 348px;
  background-color: white;
  border: 2px solid black;
  display: flex;
  flex-flow: column;
  /* overflow: hidden; */
}

.pattern-card p {
  margin-block-start: 0em;
  margin-block-end: 0em;
}

.pattern-card p:hover {
  cursor: default;
}

.fav-button {
  position: absolute;
  margin-left: 216px;
  margin-top: 280px;
  font-size: 48px;
  color: white;
  transition: color 0.3s ease-in, -webkit-text-stroke-color 0.3s ease-in;
  -webkit-text-stroke-width: 1px; 
  -webkit-text-stroke-color: black;
}

.fav-button:hover {
  margin-top: 276px;
  margin-left: 212px;
  font-size: 56px;
  cursor: pointer;
}

.pattern {
  margin: 12px;
  width: 256px;
  height: 256px;
  image-rendering: pixelated;
  background-color: white;
  border: 2px solid black;
  /* transition: 0.1s ease-out; */
}

.pattern:hover {
  margin: 10px;
  border: 4px solid black;
}

/* Radio buttons */

/* Text Input */

.login-page {
  display: flex;
  justify-content: center;
}

.login-container {
  width: 20%;
}

.title-input {
  margin: 8px;
  font-size: 18px;
  font-weight: bold;
}

input[type=text], input[type=password] {
  width: 100%;
  padding: 8px 16px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border: 2px solid black;
  font-size: 16px;
  /* border-radius: 4px; */
  box-sizing: border-box;
}

input[type=submit] {
  width: 100%;
  background-color: rgb(51, 72, 192);
  color: white;
  padding: 7px 20px;
  margin: 8px 0;
  border: 2px solid black;
  font-size: 18px;
  font-weight: bold;
  /* border-radius: 4px; */
  cursor: pointer;
}

input[type=submit]:hover {
  border: 4px solid black;
  margin: 6px 0;
  background-color: rgb(143, 207, 145);
}

/* CRUD */

.crud-container {
  /* border: 2px solid black; */
  display: flex;
  flex-flow: row wrap;
}

.crud-container h3 {
  padding: 6px;
  border: 2px solid black;
  margin: 8px;
  color: white;
  background-color: rgb(51, 72, 192);
}

.crud-container h3:hover {
  border: 4px solid black;
  margin: 6px;
  cursor: pointer;
}

.export-container {
  /* border: 2px solid black; */
  display: flex;
  flex-flow: column wrap;
}

.export-container h3 {
  padding: 6px;
  border: 2px solid black;
  margin: 12px;
  color: white;
  background-color: rgb(51, 72, 192);
}

.export-container h3:hover {
  border: 4px solid black;
  margin: 10px;
  background-color: rgb(143, 207, 145);
  cursor: pointer;
}

.create:hover {
  background-color: rgb(143, 207, 145);
}

.update:hover {
  background-color: rgb(235, 211, 104);
}

.delete:hover {
  background-color: rgb(214, 76, 66);
}

/* Other Tools */

.tool-container {
  border: 2px solid black;
  display: flex;
  flex-flow: column wrap;
  margin: 8px;
  color: white;
  background-color: rgb(83, 83, 83);
}

input[type=radio] {
  display: none;
}

.tools {
  display: flex;
  flex-flow: row wrap;
  margin: 8px;
}

.tool {
  margin: 2px;
  width: 32px;
  height: 32px;
  color: black;
  border: 2px solid black;
  -webkit-filter: grayscale(100%);
}

.tool:hover {
  margin: 0px;
  border: 4px solid rgb(143, 207, 145);
  -webkit-filter: grayscale(0%);
}

/** Settings **/

.settings {
  display: flex;
  flex-flow: row wrap;
}

.BG-container {
  padding: 8px;
  width: 88px;
  margin: 8px;
  border: 2px solid black;
  display: flex;
  color: white;
  flex-flow: column wrap;
  align-items: center;
  background-color: rgb(83, 83, 83);
}

.setBG {
  margin-top: 8px;
  width: 80px;
  /* border: 2px solid black; */
  display: flex;
  flex-flow: row wrap;
}

.bg-swatch {
  margin: 2px;
  width: 32px;
  height: 32px;
  border: 2px solid black;
}

.bg-swatch:hover {
  margin: 0px;
  border: 4px solid rgb(143, 207, 145);
}

.start-new {
  margin: 8px;
  padding: 6px;
  border: 2px solid black;
  display: flex;
  color: white;
  flex-flow: column wrap;
  background-color: rgb(83, 83, 83);
}

.keep-palette {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  height: 16px;
}

.keep-palette p {
  margin-left: 8px;
  margin-top: -1px;
}

input[type=checkbox]:before {
  position: relative;
  margin: -5px;
  display: block;
  width: 16px;
  height: 16px;
  border: 2px solid black;
  content: "";
  background: gray;
}

input[type=checkbox]:after {
  position: relative;
  display: block;
  left: -5px;
  top: -15px;
  width: 16px;
  height: 16px;
  border: 2px solid black;
  content: "";
  background-image: linear-gradient(135deg, gray 0%, gray 100%);
  background-repeat: no-repeat;
  background-position: center;
}

input[type=checkbox]:checked:after {
  background-image: url('https://i.imgur.com/sDPlD4Z.png'), linear-gradient(135deg, rgb(143, 207, 145) 0%, rgb(143, 207, 145) 100%);
  border: 4px solid black;
  left: -7px;
  top: -17px;
}

input[type=checkbox]:hover:after {
  border: 4px solid rgb(143, 207, 145);
  left: -7px;
  top: -17px;
}

/* input[type=checkbox]:checked:hover:after {
  border: 4px solid black;
  left: -7px;
  top: -17px;
} */

.sizes {
  margin: 8px;
  display: flex;
  flex-flow: row wrap;
  font-size: 12px;
}

.size-card {
  display: flex;
  flex-flow: column wrap;
  margin: 4px;
  align-items: center;
  justify-content: flex-end;
}

.size-card p {
  margin: 0px;
  margin-top: 4px;
}

.sixty-four {
  width: 48px;
  height: 48px;
  background-color: gray;
  border: 2px solid black;
}

.sixty-four:hover {
  margin: -2px;
  border: 4px solid rgb(143, 207, 145)
}

.thirty-two {
  width: 32px;
  height: 32px;
  background-color: gray;
  border: 2px solid black;
}

.thirty-two:hover {
  margin: -2px;
  border: 4px solid rgb(143, 207, 145)
}

.sixteen {
  width: 16px;
  height: 16px;
  background-color: gray;
  border: 2px solid black;
}

.sixteen:hover {
  margin: -2px;
  border: 4px solid rgb(143, 207, 145)
}

.start-new input[type=submit] {
  margin: 0px;
}

/* Profile */

.profile-switch {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}

.profile-switch h3 {
  margin-left: 32px;
  margin-right: 32px;
}